import React from "react"
import { Link } from "gatsby"
import PageLayout from "../components/new-pagelayout"
import { StaticImage } from "gatsby-plugin-image"
//import * as productStyles from "./produkter/produkter.module.scss"

const ProductText = ({ productName, text, points }) => {
  return (
    <div class="relative w-full lg:w-1/2 px-4 pb-12 lg:pb-0">
      <div class="max-w-3xl mx-auto">
        <h2 class="mb-6 lg:mb-10 text-4xl font-semibold font-heading">
          {productName}
        </h2>
        <p class="mb-6 lg:mb-10 text-xl text-gray-500">{text}</p>
        <div class="mb-10 border rounded-lg">
          {points.map((s, i) => {
            return (
              <div class="flex p-4 border-b items-end" key={i}>
                <svg
                  class="mr-4 mt-1"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.67 0H14.34C17.73 0 20 2.38 20 5.92V14.091C20 17.62 17.73 20 14.34 20H5.67C2.28 20 0 17.62 0 14.091V5.92C0 2.38 2.28 0 5.67 0ZM9.43 12.99L14.18 8.24C14.52 7.9 14.52 7.35 14.18 7C13.84 6.66 13.28 6.66 12.94 7L8.81 11.13L7.06 9.38C6.72 9.04 6.16 9.04 5.82 9.38C5.48 9.72 5.48 10.27 5.82 10.62L8.2 12.99C8.37 13.16 8.59 13.24 8.81 13.24C9.04 13.24 9.26 13.16 9.43 12.99Z"
                    fill="#45C1FF"
                  ></path>
                </svg>
                <h3 class="font-semibold font-heading">{s}</h3>
              </div>
            )
          })}
        </div>
        <div class="text-center">
          <Link
            to="/kontakt"
            class="inline-block px-10 py-4 bg-red-400 hover:bg-red-300 text-sm text-white font-medium leading-normal rounded transition duration-200"
          >
            Beställ mer information
          </Link>
        </div>
      </div>
    </div>
  )
}

const IndexPage = () => {
  return (
    <PageLayout pageTitle={"Produkter"}>
      <div class="container px-4 max-w-3xl mx-auto">
        <section class="relative py-20 overflow-x-hidden">
          <div class="container px-4 mx-auto">
            <div id="hitta" class="relative flex flex-wrap items-center -mx-4">
              <ProductText
                productName="Hitta"
                text={
                  <>
                    Hitta detekterar drönare i närområdet. Hitta använder väl
                    beprövad elektronik i standard CCTV-hus och det senaste inom
                    maskininlärning för beräkningar på eggen av vad som är
                    möjligt. Med hjälp av edge computing kan produkten monteras
                    i offentliga miljöer utan att strida mot europeiska
                    dataskyddsförordningen (GDPR).
                  </>
                }
                points={[
                  "Integreras med existerande alarmsystem",
                  "Uppfyller GDPR-krav",
                  "Installeras lätt via app på mobilen",
                  <>
                    Kompatibel med <Link to="#avvisa">Drone Bone Avvisa</Link>
                  </>,
                ]}
              />
              <div class="w-full lg:w-1/2 px-4">
                <StaticImage
                  alt="Drone Bone Hitta, monterade på en tegelvägg"
                  class="w-full h-112 mx-auto mb-6 md:mb-0 rounded-xl object-cover"
                  src="../images/hitta.jpeg"
                />
              </div>
            </div>
          </div>
        </section>

        <section class="relative py-20">
          <div class="container px-4 mx-auto">
            <div id="avvisa" class="flex flex-wrap items-center -mx-4">
              <div class="w-full lg:w-1/2 px-4">
                <StaticImage
                  alt="Drone Bone Avvisa, monterad på en tegelvägg"
                  class="w-full h-112 mx-auto mb-6 md:mb-0 rounded-xl object-cover"
                  src="../images/avvisa.jpeg"
                />
              </div>
              <ProductText
                productName="Avvisa"
                text={
                  <>
                    Avvisa är ett aktivt drönarförsvar för civilt bruk. Det
                    använder SDR (Software Defined Radio) för att avbryta
                    radiokommunikation mellan drönare och pilot. Konstruktionen
                    gör detta möjligt utan att överskrida tillåtna utsända
                    effekter.
                  </>
                }
                points={[
                  <>
                    Integreras med <Link to="#hitta">Drone Bone Hitta</Link>
                  </>,
                  "Arbetar inom EUs EMC direktiv",
                  "Kräver inga tillstånd för att installeras",
                  "Effektiv mot de flesta kommersiella drönare",
                ]}
              />
            </div>
          </div>
        </section>

        {/*
      <h1>Produkter</h1>
      <ul className={productStyles.products}>
        <li className={productStyles.product}>
          <Link to="/produkter/hitta">
            Drone Bone Hitta
            <StaticImage alt="Lite gräs" src="../images/hitta.jpeg" />
          </Link>
        </li>
        <li className={productStyles.product}>
          <Link to="/produkter/avvisa">
            Drone Bone Avvisa
            <StaticImage alt="Lite gräs" src="../images/avvisa.jpeg" />
          </Link>
        </li>
      </ul>
      */}
      </div>
    </PageLayout>
  )
}

export default IndexPage
